import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Room.css";

import image1 from "../assets/room3/1.jpg";
import image2 from "../assets/room3/2.jpg";
import image3 from "../assets/room3/3.jpg";
import image4 from "../assets/room3/4.jpg";
import image5 from "../assets/room3/5.jpg";
import image6 from "../assets/room3/6.jpg";
import image7 from "../assets/room3/7.jpg";
import image8 from "../assets/room3/8.jpg";
import image9 from "../assets/room3/9.jpg";
import image10 from "../assets/room3/10.jpg";
import image11 from "../assets/room3/11.jpg";
import plan from "../assets/room3/3BRplan.jpeg";

const Room3 = () => {
  const images = [
    image5,
    image2,
    image3,
    image4,
    image1,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    plan,
  ];

  return (
    <div className="container">
      <h2 className="title">3 BEDROOM APARTMENTS</h2>
      <Carousel
        showArrows
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        dynamicHeight
        emulateTouch
        autoPlay
        interval={5000}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="carousel-image"
            />
          </div>
        ))}
      </Carousel>
      <p className="description">
        {/* list */}
        <ul>
          <li>1 Separate living room with queen sleeper sofa</li>{" "}
          <li>3 Separate bedrooms with 2 queen and 2 twin size beds </li>
          <li>1 Full size kitchen</li>
          <li>1 Full size bathroom</li>
          <li>Sleeps up to 8 guests</li>
          <li>Private front & back entrance</li>
          <li>950 sq ft (88 sq meter) apartment</li>
        </ul>
      </p>
    </div>
  );
};

export default Room3;
