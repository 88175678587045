// src/Loading.js
import React from "react";
import "./Loading.css";
import logo from "../assets/logo.png"; // Adjust the path to your logo

const Loading = ({ fadeOut }) => {
  return (
    <div className={`loading ${fadeOut ? "fade-out" : ""}`}>
      <img src={logo} alt="Company Logo" className="loading-logo" />
      <br />
      <div className="spinner"></div>
    </div>
  );
};

export default Loading;
