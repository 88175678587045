import React, { useEffect } from "react";
import "./Header.css";
import logo from "../assets/logo.png";

const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

const Header = () => {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header");
      const scrollThreshold = 50;
      const bufferZone = 45; // Adjust the buffer zone as needed

      if (window.scrollY > scrollThreshold + bufferZone) {
        header.classList.add("header-small");
      } else if (window.scrollY < scrollThreshold - bufferZone) {
        header.classList.remove("header-small");
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 15);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const handleBooking = () => {
    window.location.href =
      "https://reservation.asiwebres.com/SearchAvailability.aspx?id=a49994a8807148b88099c1948a411db6";
  };

  const handleCheckInOut = () => {
    window.location.href =
      "https://motel-dessert-canyon.netlify.app/remoteCheckIn/bFHyveeOlK";
  };

  const handleLogoClick = () => {
    window.location.href = "/";
  };

  return (
    <header className="header">
      <div className="header-logo">
        <img src={logo} alt="Logo" onClick={handleLogoClick} />
        <p className="logo-caption">Apartment style accommodation</p>
      </div>
      <div className="header-buttons">
        <button className="header-button" onClick={handleBooking}>
          Book Now
        </button>
        <button className="header-button" onClick={handleCheckInOut}>
          Check In/Out
        </button>
      </div>
    </header>
  );
};

export default Header;
