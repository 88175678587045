import React from "react";
import "./Rooms.css";
import Room1 from "./Room1";
import Room3 from "./Room3";

const Rooms = () => {
  return (
    <div className="rooms-container">
      <center>
        <h1>Our Apartments</h1>
      </center>
      <div className="dual-container">
        <div className="carousel-wrapper">
          <Room1 />
        </div>
        <div className="carousel-wrapper">
          <Room3 />
        </div>
      </div>
      <br />
    </div>
  );
};

export default Rooms;
