import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./components/Header";
// import WelcomeMessage from "./components/old/WelcomeMessage";
import InfoText1 from "./components/InfoText1";
import Loading from "./components/Loading";
import InfoText2 from "./components/InfoText2";
import InfoText3 from "./components/InfoText3";
import Slideshow from "./components/Slideshow";
import Rooms from "./components/Rooms";
import Map from "./components/Map";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

function App() {
  // get google tracking ID from .env
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setFadeIn(true);
        }, 0); // Duration of the fade-out transition
        setTimeout(() => {
          setIsLoading(false);
        }, 1000); // Minimum wait time of 1 second
      }, 1000); // Minimum wait time of 1 second
    };

    handleLoad();
  }, []);

  return (
    <div>
      {isLoading && <Loading fadeOut={fadeOut} />}
      <div className={`content ${fadeIn ? "fade-in" : ""}`}>
        {fadeIn ? (
          <>
            <main>
              <Header />
              <Slideshow />
              {/* <WelcomeMessage /> */}
              <InfoText3 />
              <Rooms />
              <InfoText1 />
              <InfoText2 />

              <Map />
              <Footer />
            </main>
          </>
        ) : (
          <>
            <div className={`content ${fadeIn ? "fade-in" : ""}`}>
              <main>
                <div className="spacer"></div>
              </main>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
