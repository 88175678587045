// src/InfoText1.js
import React from "react";
import "./InfoText1.css";
import img1 from "../assets/Entrance.jpg"; // Adjust the path to your image

// for animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0.5 },
};

const InfoText1 = () => {
  // animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    console.log("text1", inView);
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  // end animation

  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="info-text1"
    >
      <img src={img1} alt="Desert Canyon Inn" className="image1" />
      <p className="text1">
        Standard hotel room is only 300 sq ft (30 sq meter), our 3-bedroom
        apartment is 950 sq ft (88 sq meter) and our 1 bed room apartment is 625
        sq ft (58 sq meter). All this will be just for you and your family for
        the same price or less than a standard hotel room.
        <br /> <br />
        We are in walking distance to the town center, to restaurants, tour
        companies, grocery store and we are within 10 minutes of a drive to
        Antelope Canyons, Horseshoe Bend and to Lake Powell.
      </p>
    </motion.div>
  );
};

export default InfoText1;
