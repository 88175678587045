// src/InfoText3.js
import React from "react";
import "./InfoText3.css";

// for animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0.5 },
};

const InfoText3 = () => {
  // animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    console.log("text2", inView);
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  // end animation

  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="info-text3"
    >
      <p className="text3">
        Why do you want to stay in a standard hotel room when you can stay in
        our apartments that are 3 times bigger, with separate bedrooms, kitchen
        and living room to yourself.
      </p>
      {/* <img src={img1} alt="Desert Canyon Inn" className="image1" /> */}
    </motion.div>
  );
};

export default InfoText3;
