// src/InfoText2.js
import React from "react";
import "./InfoText2.css";
import img1 from "../assets/Backyard.jpg"; // Adjust the path to your image

// for animation
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0.5 },
};

const InfoText2 = () => {
  // animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    console.log("text2", inView);
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);
  // end animation

  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
      className="info-text2"
    >
      <p className="text2">
        Our full kitchen is equipped to take off the burden of eating out. It is
        stocked with pots & pans, dishes, tea & coffee maker, toaster,
        microwave, full sized refrigerator and more, so you can enjoy making
        your own delicious food.
        <br />
        <br />
        Free Wi-Fi, cable TV, shared back patio with fully stocked BBQ grills,
        ready for outdoor dining. This space has features to relax, lounge, and
        feel at home. <br />
        <br />
        We have a designated free private parking lot for average sized
        vehicles. As well as free street parking for larger vehicles like RVs
        and Boats.
      </p>
      <img src={img1} alt="Desert Canyon Inn" className="image2" />
    </motion.div>
  );
};

export default InfoText2;
