import React, { useState, useEffect } from "react";
import DCImap from "../assets/DCImap.JPEG"; // Adjust the path as necessary
import "./Map.css"; // Import the CSS file

const Map = () => {
  const [zoomLevel, setZoomLevel] = useState(100);
  const [maxZoomLevel, setMaxZoomLevel] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setZoomLevel(200);
        setMaxZoomLevel(300);
      } else {
        setZoomLevel(100);
        setMaxZoomLevel(200);
      }
    };

    // Set initial zoom level based on the current window width
    handleResize();

    // Update zoom level on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleZoomIn = () => {
    if (zoomLevel < maxZoomLevel) {
      setZoomLevel(zoomLevel + 10);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 100) {
      setZoomLevel(zoomLevel - 10);
    }
  };

  return (
    <div className="mapcontainer">
      <div className="mapContainer">
        <img
          src={DCImap}
          alt="Map of DC"
          className="mapImage"
          style={{ width: `${zoomLevel}%` }}
        />
      </div>
      <div className="buttonsContainer">
        <button onClick={handleZoomIn} className="zoomButton">
          Zoom In
        </button>
        <button onClick={handleZoomOut} className="zoomButton">
          Zoom Out
        </button>
      </div>
    </div>
  );
};

export default Map;
