import React from "react";
import "./Footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <h2 className="footer-title">Contact Us</h2>
      <hr className="footer-divider" />
      <div className="footer-content">
        <div className="footer-address">
          <p className="footer-para">
            119 8<sup>th</sup> Ave, Page, AZ 86040
          </p>
          <a
            className="footer-addr-link"
            href="mailto:desertcanyoninn@gmail.com"
          >
            desertcanyoninn@gmail.com
          </a>
        </div>
        <div className="footer-contact">
          <p className="footer-para">(928) 645-1224</p>
          <p className="footer-para">(Call/Text/WhatsApp)</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="bottom-p">
          © 2024 Desert Canyon Inn. Developed by{" "}
          <a
            href="https://www.isurugunarathne.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="name-link"
          >
            Isuru Gunarathne
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
