import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Room.css";

import image1 from "../assets/room1/1.jpg";
import image2 from "../assets/room1/2.jpg";
import image3 from "../assets/room1/3.jpg";
import image4 from "../assets/room1/4.jpg";
import image5 from "../assets/room1/5.jpg";
import image6 from "../assets/room1/6.jpg";
import image7 from "../assets/room1/7.jpg";
import image8 from "../assets/room1/8.jpg";
import image9 from "../assets/room1/9.jpg";
import image10 from "../assets/room1/10.jpg";
import image11 from "../assets/room1/11.jpg";
import plan from "../assets/room1/1BRplan.jpeg";

const Room1 = () => {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    plan,
  ];

  return (
    <div className="container">
      <h2 className="title">1 BEDROOM APARTMENTS</h2>
      <Carousel
        showArrows
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        dynamicHeight
        emulateTouch
        autoPlay
        interval={5000}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="carousel-image"
            />
          </div>
        ))}
      </Carousel>
      <p className="description">
        {/* list */}
        <ul>
          <li>1 Separate living room with queen sleeper sofa</li>{" "}
          <li>1 Separate bedroom with a queen size bed</li>
          <li>1 Full size kitchen</li>
          <li>1 Full size bathroom</li>
          <li>Sleeps up to 4 guests</li>
          <li>Private front & back entrance</li>
          <li>625 sq ft (58 sq meter) apartment</li>
        </ul>
      </p>
    </div>
  );
};

export default Room1;
